export default [
  {
    path: '/vehicle-model',
    name: 'list-vehicle-model',
    component: () => import('@/views/vehicleModel/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.model',
      resource: 'vehicle-model',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listModel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle-model/create',
    name: 'create-vehicle-model',
    component: () => import('@/views/vehicleModel/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.model',
      navActiveLink: 'list-vehicle-model',
      resource: 'vehicle-model',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listModel',
          to: { name: 'list-vehicle-model' }
        },
        {
          text: 'breadcrumb.createModel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle-model/:id/update',
    name: 'update-vehicle-model',
    component: () => import('@/views/vehicleModel/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.model',
      navActiveLink: 'list-vehicle-model',
      resource: 'vehicle-model',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listModel',
          to: { name: 'list-vehicle-model' }
        },
        {
          text: 'breadcrumb.updateModel',
          active: true,
        },
      ],
    },
  },
]
