export default [
  {
    path: '/zone',
    name: 'list-zone',
    component: () => import('@/views/zone/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.zone',
      resource: 'user',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listZone',
          active: true,
        },
      ],
    },
  },
  {
    path: '/zone/create',
    name: 'create-zone',
    component: () => import('@/views/zone/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.zone',
      navActiveLink: 'list-zone',
      resource: 'user',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listZone',
          to: { name: 'list-zone' }
        },
        {
          text: 'breadcrumb.createZone',
          active: true,
        },
      ],
    },
  },
  {
    path: '/zone/:id/update',
    name: 'update-zone',
    component: () => import('@/views/zone/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.zone',
      navActiveLink: 'list-zone',
      resource: 'user',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listZone',
          to: { name: 'list-zone' }
        },
        {
          text: 'breadcrumb.updateZone',
          active: true,
        },
      ],
    },
  },
]
