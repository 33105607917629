export default [
  {
    path: '/payment',
    name: 'list-payment',
    component: () => import('@/views/payment/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.payment',
      resource: 'payment',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPayment',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/payment/create',
  //   name: 'create-payment',
  //   component: () => import('@/views/payment/Create.vue'),
  //   meta: {
  //     pageTitle: 'breadcrumb.payment',
  //     navActiveLink: 'list-payment',
  //     resource: 'payment',
  //     action: 'create',
  //     breadcrumb: [
  //       {
  //         text: 'breadcrumb.listPayment',
  //         to: { name: 'list-payment' }
  //       },
  //       {
  //         text: 'breadcrumb.createPayment',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
