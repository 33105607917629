import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/payment`;

export default {
  options(payload) {
    const queries = [
      {
        searchby: "model",
        searchoperator: "%",
        search: payload.search
      },
      {
        searchby: "vin_number",
        searchoperator: "%",
        search: payload.search
      },
    ];
    let params = prepareOptionFilterParams(payload, queries);
    payload.query.forEach(element => {
      params[element.searchby] = element.search;
    });
    return Client.post(resource, params, {
      headers: {
        action: "option-vehicle",
      },
    });
  },
};
