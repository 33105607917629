export default [
  {
    path: '/logistic-inspector',
    name: 'list-logistic-inspector',
    component: () => import('@/views/logisticInspector/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.logisticInspector',
      resource: 'logistic-inspector',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLogisticInspector',
          active: true,
        },
      ],
    },
  },
  {
    path: '/logistic-inspector/create',
    name: 'create-logistic-inspector',
    component: () => import('@/views/logisticInspector/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.logisticInspector',
      navActiveLink: 'list-logistic-inspector',
      resource: 'logistic-inspector',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listLogisticInspector',
          to: { name: 'list-logistic-inspector' }
        },
        {
          text: 'breadcrumb.createLogisticInspector',
          active: true,
        },
      ],
    },
  },
  {
    path: '/logistic-inspector/:id/update',
    name: 'update-logistic-inspector',
    component: () => import('@/views/logisticInspector/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.logisticInspector',
      navActiveLink: 'list-logistic-inspector',
      resource: 'logistic-inspector',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listLogisticInspector',
          to: { name: 'list-logistic-inspector' }
        },
        {
          text: 'breadcrumb.updateLogisticInspector',
          active: true,
        },
      ],
    },
  },
  {
    path: '/logistic-inspector/:id',
    name: 'view-logistic-inspector',
    component: () => import('@/views/logisticInspector/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.logisticInspector',
      navActiveLink: 'list-logistic-inspector',
      resource: 'logistic-inspector',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLogisticInspector',
          to: { name: 'list-logistic-inspector' }
        },
        {
          text: 'breadcrumb.viewLogisticInspector',
          active: true,
        },
      ],
    },
  },
]
