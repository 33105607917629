import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/payment/document`;

export default {
  index(id) {
    return Client.post(resource, { paymentId: id }, {
      headers: {
        action: "list",
      },
    });
  },
  upload(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "upload",
      },
    });
  },
}
