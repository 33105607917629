export default [
  {
    path: '/auction-schedule',
    name: 'list-auction-schedule',
    component: () => import('@/views/auctionSchedule/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.marketplace',
      resource: 'auction-schedule',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAuctionSchedule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/auction-vehicle',
    name: 'list-action-vehicle',
    component: () => import('@/views/auctionSchedule/vehicleList/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.marketplace',
      navActiveLink: 'list-auction-schedule',
      resource: 'auction-schedule',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listVehicle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/auction-schedule/:id/view',
    name: 'view-auction-schedule',
    component: () => import('@/views/auctionSchedule/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.marketplace',
      navActiveLink: 'list-auction-schedule',
      resource: 'auction-schedule',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAuctionSchedule',
          to: { name: 'list-auction-schedule' }
        },
        {
          text: 'breadcrumb.viewAuctionSchedule',
          active: true,
        },
      ],
    },
  },
]
