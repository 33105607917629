export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      pageTitle: 'breadcrumb.login',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/forgotPassword/requestOtp/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      pageTitle: 'breadcrumb.forgotPassword',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/verify-otp',
    name: 'verify-otp',
    component: () => import('@/views/forgotPassword/verifyOtp/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      pageTitle: 'breadcrumb.verifyOtp',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/forgotPassword/resetPassword/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      pageTitle: 'breadcrumb.resetPassword',
      redirectIfLoggedIn: true,
    },
  },
]
