import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/dashboard`;

export default {
  index() {
    return Client.post(resource, {}, {
      headers: {
        action: "list",
      },
    });
  },
  paymentChart(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "get-payment-chart",
      },
    });
  },
};
