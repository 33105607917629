import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/forgot-password`;

export default {
  requestOtp(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "request-otp",
      },
    });
  },
  verifyOtp(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "verify-otp",
      },
    });
  },
  resetPassword(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "reset-password",
      },
    });
  },
};
