import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/config`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
};
