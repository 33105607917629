import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/vehicle/update-request`;

export default {
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  review(id, payload) {
    const params = {
      id: id,
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "review",
      },
    });
  },
};
