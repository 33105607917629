import ProfileRepository from "./Api/ProfileRepository";
import DashboardRepository from "./Api/DashboardRepository";
import UserRepository from "./Api/UserRepository";
import UserMobileRepository from "./Api/UserMobileRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import DepartmentRepository from "./Api/DepartmentRepository";
import VehicleBrandRepository from "./Api/VehicleBrandRepository";
import VehicleColorRepository from "./Api/VehicleColorRepository";
import VehicleFuelTypeRepository from "./Api/VehicleFuelTypeRepository";
import VehicleRepository from "./Api/VehicleRepository";
import ImportVehicleRepository from "./Api/ImportVehicleRepository";
import ZoneRepository from "./Api/ZoneRepository";
import LotRepository from "./Api/LotRepository";
import DealerRepository from "./Api/DealerRepository";
import ProvinceRepository from "./Api/ProvinceRepository";
import InspectionRepository from "./Api/InspectionRepository";
import VehicleLogRepository from "./Api/VehicleLogRepository";
import VehicleUpdateRequestRepository from "./Api/VehicleUpdateRequestRepository";
import VehicleCommentRepository from "./Api/VehicleCommentRepository";
import AuctionScheduleRepository from "./Api/AuctionScheduleRepository";
import BidRepository from "./Api/BidRepository";
import PaymentRepository from "./Api/PaymentRepository";
import PaymentDealerRepository from "./Api/PaymentDealerRepository";
import PaymentVehicleRepository from "./Api/PaymentVehicleRepository";
import ConfigurationRepository from "./Api/ConfigurationRepository";
import GuidelineRepository from "./Api/GuidelineRepository";
import VehicleStatusRepository from "./Api/VehicleStatusRepository";
import VehicleGatePassRepository from "./Api/VehicleGatePassRepository";
import VehicleModelRepository from "./Api/VehicleModelRepository";
import InpsectorRepository from "./Api/InpsectorRepository";
import AuctionScheduleRemarkRepository from "./Api/AuctionScheduleRemarkRepository";
import PaymentAdjustmentRepository from "./Api/PaymentAdjustmentRepository";
import PaymentDocumentRepository from "./Api/PaymentDocumentRepository";
import PaymentHistoryRepository from "./Api/PaymentHistoryRepository";
import DealerRemarkRepository from "./Api/DealerRemarkRepository";
import DistrictRepository from "./Api/DistrictRepository";
import ForgotPasswordRepository from "./Api/ForgotPasswordRepository";
import LocationRepository from "./Api/LocationRepository";
import VehicleTransferLogRepository from "./Api/VehicleTransferLogRepository";

const repositories = {
  'profile': ProfileRepository,
  'dashboard': DashboardRepository,
  'user': UserRepository,
  'userMobile': UserMobileRepository,
  'role': RoleRepository,
  'permission': PermissionRepository,
  'file': FileRepository,
  'department': DepartmentRepository,
  'vehicleBrand': VehicleBrandRepository,
  'vehicleColor': VehicleColorRepository,
  'vehicleFuelType': VehicleFuelTypeRepository,
  'vehicle': VehicleRepository,
  'importVehicle': ImportVehicleRepository,
  'zone': ZoneRepository,
  'lot': LotRepository,
  'dealer': DealerRepository,
  'dealerRemark': DealerRemarkRepository,
  'province': ProvinceRepository,
  'district': DistrictRepository,
  'inspection': InspectionRepository,
  'vehicleLog': VehicleLogRepository,
  'vehicleUpdateRequest': VehicleUpdateRequestRepository,
  'vehicleComment': VehicleCommentRepository,
  'auctionSchedule': AuctionScheduleRepository,
  'auctionScheduleRemark': AuctionScheduleRemarkRepository,
  'bid': BidRepository,
  'payment': PaymentRepository,
  'paymentDealer': PaymentDealerRepository,
  'paymentVehicle': PaymentVehicleRepository,
  'configuration': ConfigurationRepository,
  'guideline': GuidelineRepository,
  'vehicleStatus': VehicleStatusRepository,
  'vehicleGatePass': VehicleGatePassRepository,
  'vehicleModel': VehicleModelRepository,
  'inspector': InpsectorRepository,
  'paymentAdjustment': PaymentAdjustmentRepository,
  'paymentDocument': PaymentDocumentRepository,
  'paymentHistory': PaymentHistoryRepository,
  'forgotPassword': ForgotPasswordRepository,
  'location': LocationRepository,
  'vehicleTransferLog': VehicleTransferLogRepository,
};

export default {
  get: name => repositories[name]
};
