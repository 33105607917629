import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/dealer/remark`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
};
