<template>
  <div>
    <b-input-group @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <b-form-input :value="item.fileName" :disabled="true"></b-form-input>
      <b-input-group-append>
        <b-button
          variant="outline-secondary"
          size="sm"
          v-if="item.fileUrl"
          @click="preview"
        >
          {{ $t("button.preview") }}
        </b-button>
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="chooseFile"
          :disabled="readonly"
        >
          <b-spinner small v-if="loading" />
          <span v-else>{{ $t("button.browse") }}</span>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <input
      type="file"
      style="display: none"
      name="fields[assetsFieldHandle][]"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange"
      ref="fileInput"
      :accept="accept"
    />
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import Repository from "@/repositories/RepositoryFactory";

const FileRepository = Repository.get("file");

export default {
  delimiters: ["${", "}"],
  components: { BInputGroup, BFormInput, BInputGroupAppend, BButton, BSpinner },

  data: function () {
    return {
      loading: false,
      filelist: [],
      item: {},
    };
  },

  props: {
    value: {},
    readonly: {},
    accept: {
      type: String,
      default: ".pdf,.jpg,.jpeg,.png",
    },
    file: {
      type: Object,
      default: () => {
        return {
          mediaId: null,
        };
      },
    },
  },
  watch: {
    "file.mediaId"() {
      this.item = { ...this.file };
    },
  },
  methods: {
    preview() {
      if (this.item.fileType.startsWith("image/")) {
        this.$imagePreview({
          initIndex: 0,
          images: [this.item.fileUrl],
          zIndex: 9999,
          isEnableDownloadImage: false,
          isEnableImagePageIndicator: false,
          isEnableBlurBackground: true,
          onClose: () => {},
        });
      } else {
        window.open(this.item.fileUrl, "_blank").focus();
      }
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    onChange() {
      if (this.readonly || this.loading) {
        return;
      }

      this.filelist = [...this.$refs.fileInput.files];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.uploadFile(this.filelist[0]);
      };
      reader.readAsDataURL(this.filelist[0]);
    },
    uploadFile(event) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", event);
      FileRepository.uploadStream(formData)
        .then((response) => {
          const res = response?.data?.data;
          if (res) {
            this.item = { ...res };
            this.$emit("input", res.mediaId);
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    remove() {
      this.filelist.splice(0, 1);
      this.$emit("input", null);
      this.$emit("change", null);
    },
    dragover(event) {
      if (this.readonly) {
        return;
      }

      event.preventDefault();
      this.activeColor = this.primary;
    },
    dragleave(event) {
      if (this.readonly) {
        return;
      }
      this.activeColor = this.secondary;
    },
    drop(event) {
      if (this.readonly) {
        return;
      }
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    chooseFile() {
      if (this.readonly) {
        return;
      }
      this.$emit("blur", null);
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
  },
  setup() {
    // App Name
    const { primary, secondary } = $themeConfig.color;

    let activeColor = secondary;

    return {
      primary,
      secondary,
      activeColor,
    };
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
