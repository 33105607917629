export default [
  {
    path: '/vehicle',
    name: 'list-vehicle',
    component: () => import('@/views/vehicle/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.vehicle',
      resource: 'vehicle',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listVehicle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle/create',
    name: 'create-vehicle',
    component: () => import('@/views/vehicle/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.vehicle',
      navActiveLink: 'list-vehicle',
      resource: 'vehicle',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listVehicle',
          to: { name: 'list-vehicle' }
        },
        {
          text: 'breadcrumb.createVehicle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle/:id',
    name: 'view-vehicle',
    component: () => import('@/views/vehicle/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.vehicle',
      navActiveLink: 'list-vehicle',
      resource: 'vehicle',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listVehicle',
          to: { name: 'list-vehicle' }
        },
        {
          text: 'breadcrumb.viewVehicle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle/:id/inspection-report',
    name: 'view-vehicle-inspection-report',
    component: () => import('@/views/vehicle/InspectionReport.vue'),
    meta: {
      pageTitle: 'breadcrumb.vehicle',
      navActiveLink: 'list-vehicle',
      resource: 'vehicle',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listVehicle',
          to: { name: 'list-vehicle' }
        },
        {
          text: 'breadcrumb.viewVehicle',
          active: true,
        },
      ],
    },
  },
]
