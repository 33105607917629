import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/bid`;

export default {
  index(payload) {
    let queries = [];
    if (payload.bidDate) {
      queries = [
        {
          searchby: "start_date",
          searchoperator: "dt>=",
          search: `${payload.bidDate} 00:00:00`,
        },
        {
          searchby: "start_date",
          searchoperator: "dt<=",
          search: `${payload.bidDate} 23:59:59`,
        }
      ]

      payload.bidDate = null;
    }
    let params = prepareFilterParams(payload, queries);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  exportExcel(payload) {
    let queries = [];
    if (payload.bidDate) {
      queries = [
        {
          searchby: "start_date",
          searchoperator: "dt>=",
          search: `${payload.bidDate} 00:00:00`,
        },
        {
          searchby: "start_date",
          searchoperator: "dt<=",
          search: `${payload.bidDate} 23:59:59`,
        }
      ]

      payload.bidDate = null;
    }
    let params = prepareFilterParams(payload, queries);
    params.fields = payload.fields;
    return Client.post(resource, params, {
      headers: {
        action: "export-excel",
      },
      responseType: "blob",
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update-vehicle-bid",
      },
    });
  },
  reassign(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "reassign-vehicle-bid",
      },
    });
  },
};
