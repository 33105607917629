export default [
  {
    path: '/vehicle-fuel-type',
    name: 'list-vehicle-fuel-type',
    component: () => import('@/views/vehicleFuelType/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.fuelType',
      resource: 'vehicle-fuel-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listFuelType',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle-fuel-type/create',
    name: 'create-vehicle-fuel-type',
    component: () => import('@/views/vehicleFuelType/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.fuelType',
      navActiveLink: 'list-vehicle-fuel-type',
      resource: 'vehicle-fuel-type',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listFuelType',
          to: { name: 'list-vehicle-fuel-type' }
        },
        {
          text: 'breadcrumb.createFuelType',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle-fuel-type/:id/update',
    name: 'update-vehicle-fuel-type',
    component: () => import('@/views/vehicleFuelType/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.fuelType',
      navActiveLink: 'list-vehicle-fuel-type',
      resource: 'vehicle-fuel-type',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listFuelType',
          to: { name: 'list-vehicle-fuel-type' }
        },
        {
          text: 'breadcrumb.updateFuelType',
          active: true,
        },
      ],
    },
  },
]
