export default [
  {
    path: '/user',
    name: 'list-user',
    component: () => import('@/views/user/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.user',
      resource: 'user',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listUser',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/create',
    name: 'create-user',
    component: () => import('@/views/user/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.user',
      navActiveLink: 'list-user',
      resource: 'user',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listUser',
          to: { name: 'list-user' }
        },
        {
          text: 'breadcrumb.createUser',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id/update',
    name: 'update-user',
    component: () => import('@/views/user/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.user',
      navActiveLink: 'list-user',
      resource: 'user',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listUser',
          to: { name: 'list-user' }
        },
        {
          text: 'breadcrumb.updateUser',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id',
    name: 'view-user',
    component: () => import('@/views/user/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.user',
      navActiveLink: 'list-user',
      resource: 'user',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listUser',
          to: { name: 'list-user' }
        },
        {
          text: 'breadcrumb.viewUser',
          active: true,
        },
      ],
    },
  },
]
