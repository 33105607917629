var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{style:({
      width: _vm.fullWidth ? '' : (_vm.pw + "px"),
      height: _vm.fullWidth ? '' : ((_vm.ph + 2) + "px"),
    }),attrs:{"show":_vm.loading},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("general.uploading")))]),_c('p',[_vm._v(_vm._s(_vm.uploadPercentage)+"%")])],1)]},proxy:true}])},[_c('div',{ref:"dragDropBox",staticClass:"image-uploader flex-center-vertically text-center",staticStyle:{"min-height":"150px","height":"auto","border":"1px dashed"},style:({
        borderColor: _vm.activeColor,
        cursor: _vm.readonly ? '' : 'pointer',
        backgroundColor: _vm.readonly ? _vm.secondary : '',
        width: _vm.fullWidth ? '' : (_vm.pw + "px"),
        height: _vm.fullWidth ? '' : ((_vm.ph + 2) + "px"),
      }),on:{"click":_vm.chooseImage,"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[(!_vm.uploadedFile && !_vm.image)?_c('div',[_c('feather-icon',{style:({
            color: _vm.readonly ? '#ffffff' : _vm.activeColor,
          }),attrs:{"icon":"ImageIcon","size":"30"}}),_c('div',{staticClass:"mt-2",style:({
            color: _vm.readonly ? '#ffffff' : _vm.activeColor,
          })},[_vm._v(" "+_vm._s(_vm.$t("button.clickOrDragDropToUpload"))+" ")])],1):_vm._e(),(_vm.uploadedFile || _vm.image)?_c('div',[_c('img',{staticClass:"img-fluid",style:({
            height: _vm.fullWidth ? '' : (_vm.ph + "px"),
          }),attrs:{"src":_vm.uploadedFile ? _vm.uploadedFile : _vm.image,"alt":""}})]):_vm._e()]),_c('input',{ref:"fileInput",staticClass:"w-px h-px opacity-0 overflow-hidden absolute",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":"","name":"fields[assetsFieldHandle][]","id":"assetsFieldHandle","accept":".pdf,.jpg,.jpeg,.png"},on:{"change":_vm.onChange}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }