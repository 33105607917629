import Vue from 'vue';

// vee-validate
import { ValidationObserver, ValidationProvider, Field, extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import i18n from '@/libs/i18n';

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
});

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // message: messages[rule] // assign message
  });
});

extend("decimal", {
  validate: (value, ref, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (ref === void 0) ref = [];
    var decimals = ref[0]; if (decimals === void 0) decimals = '*';

    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: function (field, ref) {
    if (ref === void 0) ref = [];
    var decimals = ref[0]; if (decimals === void 0) decimals = '*';

    return i18n.t(`validations.messages.decimal`, {
      '_field_': i18n.t(field),
      'decimals': (!decimals || decimals === '*' ? '' : decimals),
    });
  }
});

extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
  message: function (field) {
    return i18n.t(`validations.messages.url`, {
      '_field_': field,
    });
  },
})

extend('password', {
  validate(value) {
    if (value) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@!#$%^&*()\-_=+\\|[\]{};:'",.<>/?`~]+$/.test(value);
      // return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$/.test(value);
    }

    return false;
  },
  message: function (field) {
    return i18n.t(`validations.messages.password`, {
      '_field_': i18n.t(field),
    });
  },
})

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
