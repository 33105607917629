<template>
  <b-input-group
    :prepend="prefix"
    :append="suffix"
    :class="errors.length > 0 ? 'is-invalid' : null"
  >
    <b-form-input
      class="form-control"
      :value="value"
      :disabled="true"
      :placeholder="placeholder"
      @input="input"
    />
    <b-input-group-append>
      <b-button variant="primary" @click="toggleColorPicker">
        <feather-icon icon="Edit2Icon" v-if="!showPicker" />
        <feather-icon icon="XIcon" v-else />
      </b-button>
    </b-input-group-append>
    <div ref="colorPicker" class="float-color-picker">
      <sketch :value="color" @input="updateValue" v-show="showPicker" />
    </div>
  </b-input-group>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import { Sketch } from "vue-color";

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    Sketch,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {
      default: "",
    },
    suffix: {
      default: "",
    },
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    value(value) {
      if (this.color.hex != value) {
        this.color.hex = value;
      }
    },
  },
  data() {
    return {
      showPicker: false,
      color: {
        hex: "",
      },
    };
  },
  methods: {
    updateValue(value) {
      this.input(value.hex);
    },
    input(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    toggleColorPicker() {
      this.showPicker = !this.showPicker;
      if (this.showPicker && this.$refs.colorPicker) {
        this.$refs.colorPicker.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
