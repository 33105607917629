import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/zone`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  detail(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "detail",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  destroy(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "delete",
      },
    });
  },
  options(payload) {
    const queries = [{
      searchby: "name",
      searchoperator: "%",
      search: payload.search
    }];
    let params = prepareOptionFilterParams(payload, queries);
    if (payload.query) {
      let filters = {
        filters: [],
        filtertype: "AND",
      };
      payload.query.forEach(element => {
        filters.filters.push(element);
      });
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
