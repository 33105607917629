export default [
  {
    path: '/vehicle-color',
    name: 'list-vehicle-color',
    component: () => import('@/views/vehicleColor/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.color',
      resource: 'vehicle-color',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listColor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle-color/create',
    name: 'create-vehicle-color',
    component: () => import('@/views/vehicleColor/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.color',
      navActiveLink: 'list-vehicle-color',
      resource: 'vehicle-color',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listColor',
          to: { name: 'list-vehicle-color' }
        },
        {
          text: 'breadcrumb.createColor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicle-color/:id/update',
    name: 'update-vehicle-color',
    component: () => import('@/views/vehicleColor/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.color',
      navActiveLink: 'list-vehicle-color',
      resource: 'vehicle-color',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listColor',
          to: { name: 'list-vehicle-color' }
        },
        {
          text: 'breadcrumb.updateColor',
          active: true,
        },
      ],
    },
  },
]
