import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/province`;

export default {
  options(payload) {
    const queries = [{
      searchby: "name_en",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name_km",
      searchoperator: "%",
      search: payload.search
    }];
    const params = prepareOptionFilterParams(payload, queries, "OR", "id", "asc", false);
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
