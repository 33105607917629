<template>
  <v-select
    v-model="selected"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :label="selectionLabel"
    :disabled="readonly"
    :placeholder="placeholder"
    :options="options"
    :loading="loading"
    :reduce="(item) => item[selectionKey]"
    :clearable="clearable"
  >
    <template #selected-option="item">
      <span v-if="translatable">
        {{ $t(item[selectionLabel]) }}
      </span>
      <span v-else>
        {{ item[selectionLabel] }}
      </span>
    </template>
    <template #option="item">
      <span v-if="translatable">
        {{ $t(item[selectionLabel]) }}
      </span>
      <span v-else>
        {{ item[selectionLabel] }}
      </span>
    </template>
    <template #no-options>
      {{ $t("field.noOption") }}
    </template>
    <template #spinner="{ loading }">
      <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectionKey: {
      type: String,
      default: "value",
    },
    selectionLabel: {
      type: String,
      default: "label",
    },
    translatable: {
      type: Boolean,
      default: false,
    },
    initOptions: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      loading: false,
      loadedInitOptions: false,
    };
  },
  watch: {
    selected: function (value) {
      this.$emit("input", value);
    },
    initOptions: function (value) {
      if (!this.loadedInitOptions) {
        this.selected = this.initOptions;
        this.loadedInitOptions = true;
      }
    },
  },
  methods: {
    reset() {
      this.selected = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>