import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/auth`;

export default {
  updatePassword(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'change-password',
      }
    });
  },
  resetPassword(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'reset-password',
      }
    });
  },
  updateProfile(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'update-profile',
      }
    });
  },
  getProfile(payload = {}) {
    const params = {
      ...payload,
    };
    return Client.post(resource, {
      params: params
    }, {
      headers: {
        action: 'get-profile',
      },
    });
  },
};
